import { render, staticRenderFns } from "./inspectionRecord.vue?vue&type=template&id=68a51c94&scoped=true"
import script from "./inspectionRecord.vue?vue&type=script&lang=js"
export * from "./inspectionRecord.vue?vue&type=script&lang=js"
import style0 from "./inspectionRecord.vue?vue&type=style&index=0&id=68a51c94&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68a51c94",
  null
  
)

component.options.__file = "inspectionRecord.vue"
export default component.exports