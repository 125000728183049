<template>
  <div class="body">
    <div class="home-class">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="电站">
          <el-input
            v-model="formInline.powerStationName"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="巡检类型">
          <el-input
            v-model="formInline.pollingType"
            placeholder="请输入巡检类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="协作人">
          <el-input
            v-model="formInline.collaborator"
            placeholder="请输入协作人"
          ></el-input>
        </el-form-item>
        <el-form-item label="首次工作时间">
          <el-date-picker v-model="formInline.starttime" type="datetime">
          </el-date-picker>
          <!-- </el-time-picker> -->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="hand-class">
        <!-- <img
          src="../../assets/images/yunwei/add.png"
          @click="editClick(1)"
          alt=""
        /> -->
        <img
          src="../../assets/images/yunwei/edit.png"
          @click="editClick(0)"
          alt=""
        />
        <img
          src="../../assets/images/yunwei/delete.png"
          @click="deleteClick"
          alt=""
        />
        <img
          src="../../assets/images/yunwei/export.png"
          @click="exportClick"
          alt=""
        />
      </div>
      <div class="table-box">
        <!-- 表格 -->
        <el-table
          :data="tableData"
          highlight-current-row
          style="width: 100%"
          class="table-style"
          height="71vh"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="巡查类型" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.pollingType }}</span>
            </template>
          </el-table-column>

          <el-table-column label="巡视开始时间" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.starttime }}</span>
            </template>
          </el-table-column>

          <el-table-column label="巡视结束时间" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.endtime }}</span>
            </template>
          </el-table-column>

          <el-table-column label="协作人" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.collaborator }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否完成" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.yesandno ? "是" : "否" }}</span>
            </template>
          </el-table-column>

          <el-table-column label="备注" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            fixed="right"
            width="180"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="light"
                content="编辑"
                placement="bottom"
              >
                <i
                  class="el-icon-edit textblue f16"
                  @click="gotoDetail(scope.row)"
                ></i>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[2, 5, 10, 15]"
          :page-size="size"
          class="pagination-style"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <div v-if="addDialogVisible == true">
        <add-record-dialog
          :addDialogVisible="addDialogVisible"
          :isAdd="isAdd"
          :queryOneId="isAdd == 0 ? queryOneId : null"
          @closeAddDialog="closeAddDialog"
        ></add-record-dialog>
      </div>
      <div v-if="detailDialogVisible == true">
        <record-detail
          :detailId="detailId"
          @closeDetailDialog="closeDetailDialog"
        ></record-detail>
      </div>
      <inspection-detail
        ref="inspectionRef"
      ></inspection-detail>
    </div>
  </div>
</template>

<script>
import { formatDate } from "../../utils/formatDate";
import addRecordDialog from "./dialog/addRecordDialog.vue";
import recordDetail from "./dialog/recordDetail.vue";
import inspectionDetail from "./dialog/inspectionDetail.vue";
export default {
  components: {
    addRecordDialog,
    recordDetail,
    inspectionDetail
  },
  mounted() {
    this.getListData();
  },
  data() {
    return {
      formInline: {
        powerStationName: localStorage.getItem("powerStationName")
      },
      tableData: [{ class: 1 }],
      total: 0,
      size: 10,
      currentPage: 1,
      addDialogVisible: false, //是否显示新增/编辑弹框
      inspectionDetailDialogVisible: false,
      isAdd: 1, // 0编辑 1新增
      isBan: true, //是否禁止添加和编辑
      detailDialogVisible: false, // 是否打开详情弹框
      queryOneId: null, //单条id
      detailId: null,
    };
  },
  methods: {
    handleSizeChange(val) {
      this.size = val;
      this.getListData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getListData();
    },
    // 新增/编辑
    editClick(key) {
      if (key === 0 && this.isBan) return;
      this.isAdd = key;
      // this.queryOneId = null
      this.addDialogVisible = true;
    },
    // 关闭新增弹框
    closeAddDialog() {
      this.addDialogVisible = false;
      this.getListData();
    },
    // 关闭详情
    closeDetailDialog() {
      this.detailDialogVisible = false;
      console.log("关闭", this.detailDialogVisible);
    },
    // 查询
    onSubmit() {
      this.getListData();
    },
    // 巡检项目
    detailClick(id) {
      console.log("详情", this.detailDialogVisible);

      this.detailId = id;
      this.detailDialogVisible = true;
    },
    gotoDetail(row) {
      this.$refs.inspectionRef.init(row);
    },
    //表格勾选
    handleSelectionChange(val) {
      this.selectList = [];
      val.forEach(item => {
        this.selectList.push(item.id);
      });
      if (val.length != 1) {
        this.isBan = true;
      } else {
        this.isBan = false;
        this.queryOneId = val[0].id;
      }
    },
    async getListData() {
      if (this.formInline.starttime) {
        this.formInline.starttime = formatDate(
          new Date(this.formInline.starttime),
          "yyyy-MM-dd hh:mm:ss"
        );
      }
      let params = {
        pageSize: this.size,
        currPage: this.currentPage,
        condition: this.formInline
      };
      params.condition.powerStationCode = localStorage.getItem(
        "powerStationCode"
      );
      params.condition.powerStationName = localStorage.getItem(
        "powerStationName"
      );
      let res = await this.$http.post("pollingPlanRecord/list", params);
      if (res.data.code == 200) {
        this.tableData = res.data.data.data;
        this.total = res.data.data.count;
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 导出
    async exportClick() {
      let res = await this.$http.post("pollingPlanRecord/Exp");
      if (res.data.code == 200) {
        window.open(res.data.data.data);
      } else {
        this.$message.error(res.data.message);
      }
    },
    //删除
    async deleteClick() {
      let delList = this.selectList;
      let res = await this.$http.post("pollingPlanRecord/deleteBatch", [
        ...delList
      ]);
      if (res.data.code == 200) {
        this.$message.success("删除成功");
        this.getListData();
      } else {
        this.$message.error(res.data.message);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.body {
  background: #223f6c;
  // width: 100%;
  height: auto;
  padding: 10px;

  .home-class {
    height: calc(100vh - 105px);
    padding: 8px;
    background-color: #2d5981;
    box-sizing: border-box;

    .hand-class {
      display: flex;
      text-align: right;
      justify-content: flex-end;
      margin-bottom: 5px;

      img {
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        margin: 0 5px;
      }
    }
    .table-box {
      height: calc(100% - 70px);
      .table-style {
        margin-top: 0;
      }
    }
  }
}

/deep/ .el-form {
  display: flex;
}

/deep/ .el-form-item {
  display: flex;
  align-items: center;
}

/deep/ .el-form-item__label {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
  text-align: left;
  line-height: normal;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid rgba(151, 179, 203, 0.55);
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #f2f2f2;
  text-align: left;
  line-height: normal;
}

/deep/ .el-table--border,
.el-table--group {
  border: 1px solid rgba(151, 179, 203, 0.55);
}

/deep/ .el-table--border th {
  background-color: #21527e;

  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table th {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table td {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #223f6c !important;
}
/deep/ .el-table__body-wrapper {
  background: #2d5981;
}

/deep/ .el-table__body tr.hover-row > td {
  background-color: transparent !important;
}

/deep/ .el-pagination {
  display: flex;
  justify-content: flex-end;
}

/deep/ .el-pagination button:disabled {
  background: rgba(255, 255, 255, 0);
}

/deep/ .el-pager li.active {
  color: #f2f2f2;
  background-color: #00b2e6;

  cursor: default;
}

/deep/ .el-pagination__total {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 12px;
}

/deep/ .el-pagination__jump {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 15px;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #223f6c !important;
}
</style>
