<template>
  <div>
    <el-dialog
      :title="isAdd ? '新增巡检类型' : '修改巡检类型'"
      :visible.sync="addDialogVisible"
      @close="close"
      class="form-class"
      width="800px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        style="display: flex; flex-wrap: wrap"
        ref="form"
      >
        <el-form-item style="width: 50%" label="巡检开始时间" required>
          <el-form-item prop="starttime">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              v-model="ruleForm.starttime"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-form-item style="width: 50%" label="巡检结束时间">
          <el-form-item prop="endtime">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              v-model="ruleForm.endtime"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-form-item style="width: 50%" label="协作人" prop="collaborator">
          <el-select v-model="ruleForm.collaborator" placeholder="请选择">
            <el-option
              v-for="item in userOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item style="width: 50%" label="是否完成" prop="yesandno">
          <el-select v-model="ruleForm.yesandno" placeholder="请选择活动区域">
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 50%" label="负责人" prop="principal">
          <el-select v-model="ruleForm.principal" placeholder="请选择">
            <el-option
              v-for="item in userOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 50%" label="巡视类型" prop="pollingType">
          <el-select v-model="ruleForm.pollingType" placeholder="请选择">
            <el-option
              v-for="item in classOptions"
              :key="item.id"
              :label="item.pollingType"
              :value="item.pollingType"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 100%" label="备注" prop="remark">
          <el-input v-model="ruleForm.remark"></el-input>
        </el-form-item>
        <div class="button-class">
          <el-form-item style="width: 100%">
            <el-button type="primary" @click="submitForm('form')"
              >提交</el-button
            >
            <el-button @click="resetForm('form')">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "../../../utils/formatDate";
export default {
  props: {
    // 0 编辑 1添加
    isAdd: {
      typeof: Number,
      default: null,
    },
    queryOneId: {
      typeof: Number,
      default: null,
    },
  },
  mounted() {
    // 获取详情
    if (this.queryOneId) {
      this.getDetail();
    }
    this.userList();
    this.classList();
  },
  data() {
    return {
      addDialogVisible: true,
      ruleForm: {
        bianhao: null,
      },
      rules: {
        starttime: [
          { required: true, message: "请选择首次日期", trigger: "blur" },
        ],
        // endtime: [ { required: true, message: '请选择下次日期', trigger: 'blur' }],
        // collaborator: [ { required: true, message: '请选择协作人', trigger: 'blur' }],
        principal: [
          { required: true, message: "请选择负责人", trigger: "blur" },
        ],
        yesandno: [
          { required: true, message: "请选择是否停用", trigger: "blur" },
        ],
        pollingType: [
          { required: true, message: "请输入巡视类型", trigger: "blur" },
        ],
      },
      userOptions: [], //人员列表
      classOptions: [],
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    close() {
      this.addDialogVisible = false;
      this.$emit("closeAddDialog");
    },
    async submit() {
      let that = this;
      that.ruleForm.starttime = formatDate(
        new Date(that.ruleForm.starttime),
        "yyyy-MM-dd hh:mm:ss"
      );
      that.ruleForm.endtime = formatDate(
        new Date(that.ruleForm.endtime),
        "yyyy-MM-dd hh:mm:ss"
      );
      let res = await this.$http.post("pollingPlanRecord/save", that.ruleForm);
      if (res.data.code == 200) {
        if (this.isAdd) {
          that.$notify.success({
            title: "提示",
            message: "添加成功",
            showClose: true,
          });
        } else {
          that.$notify.success({
            title: "提示",
            message: "修改成功",
            showClose: true,
          });
        }
        this.addDialogVisible = false;
        this.$emit("closeAddDialog");
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 人员列表
    async userList() {
      let res = await this.$http.post("pollingPlan/userList");
      if (res.data.code == 200) {
        this.userOptions = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 巡检类型列表
    async classList() {
      let params = {
        pageSize: 10000,
        currPage: 1,
      };
      // params.condition.powerStationCode =
      //   localStorage.getItem("powerStationCode");
      let res = await this.$http.post("polling/list", params);
      if (res.data.code == 200) {
        this.classOptions = res.data.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 获取详情
    async getDetail() {
      let res = await this.$http.post("pollingPlanRecord/queryOne", {
        id: this.queryOneId,
      });
      if (res.data.code == 200) {
        this.ruleForm = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-class {
  .button-class {
    width: 100%;
    /deep/ .el-form-item {
      justify-content: flex-end;
    }
  }
  /deep/ .el-form {
    display: block;
  }
  /deep/ .el-form-item__label {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 15px;
    width: 115px;
    text-align: right;
  }
  /deep/ .el-input__inner {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 13px;
  }
}

::v-deep .el-checkbox {
  margin-right: 0;
}
</style>
