<template>
  <div>
    <el-dialog
      title="常规巡检"
      :visible.sync="detailVisible"
      @close="backClick"
      class="dialog-class"
      width="80%"
    >
      <div class="title-class">
        <div class="icon-class"></div>
        <div class="text-class">巡检计划</div>
      </div>
      <div class="plan-class">
        <div class="form-class">
          <div class="label-class">协作人：</div>
          <div class="data-class">{{ formObj.collaborator }}</div>
        </div>
        <div class="form-class">
          <div class="label-class">巡检类型：</div>
          <div class="data-class">{{ formObj.pollingType }}</div>
        </div>
        <div class="form-class">
          <div class="label-class">电站：</div>
          <div class="data-class">{{ formObj.powerStationName }}</div>
        </div>
        <div class="form-class">
          <div class="label-class">巡检开始时间：</div>
          <div class="data-class">{{ formObj.starttime }}</div>
        </div>
        <div class="form-class">
          <div class="label-class">备注：</div>
          <div class="data-class">{{ formObj.remark }}</div>
        </div>
      </div>
      <div class="button-class">
        <el-button type="primary" @click="backClick">返回</el-button>
        <!-- <el-button type="primary" @click="submitClick">保存</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    insDetailId: {
      typeof: Number,
      default: null
    }
  },
  mounted() {
    // this.getTableList();
  },
  data() {
    return {
      detailVisible: false,
      showForm: [
        { label: "巡视开始时间", name: "starttime", data: "" },
        { label: "巡视结束时间", name: "endtime", data: "" },
        { label: "是否完成", name: "yesandno", data: null },
        { label: "巡视类型", name: "pollingType", data: null },
        { label: "协作人", name: "collaborator", data: null },
        { label: "备注", name: "remark", data: null }  
      ],
      formObj: {
        collaborator: "",
        pollingType: "",
        powerStationName: "",
        starttime: "",
        remark: ""
      },
      tableData: [],
      total: 0,
      size: 10,
      currentPage: 1,
      row: {}
    };
  },
  methods: {
    init(row) {
      this.detailVisible = true;
      this.row = row;
      this.getDetail();                                                           
    },
    submitClick() {},
    backClick() {
      this.detailVisible = false;
      this.$emit("closeDetailDialog");
    },
    handleSizeChange(val) {
      this.size = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableList();
    },
    getTableList() {
      let params = {
        pageSize: this.size,
        currPage: this.currentPage
      };
      this.$http.post("pollingPlanRecord/list", params).then(res => {
        if (res.data.code == 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.count;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 获取详情
    async getDetail() {
      let res = await this.$http.post("pollingPlanRecord/detail", {
        id: this.row.id
      });
      if (res.data.code == 200) {
        this.formObj = res.data.data ? res.data.data : {};
      } else {
        this.$message.error(res.data.message);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-class {
  .button-class {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
  }
  .title-class {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .icon-class {
      width: 5px;
      height: 23px;
      border-radius: 5px;
      border: 1px solid #02a7f0;
      background-color: #02a7f0;
    }
    .text-class {
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #333333;
      font-size: 16px;
      margin-left: 20px;
    }
  }
  .plan-class {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .form-class {
      display: flex;
      align-content: center;
      width: 33%;
      font-size: 17px;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #333333;
      margin-bottom: 20px;
      .label-class {
        width: 50%;
        margin-right: 20px;
        display: flex;
        justify-content: right;
      }
    }
  }
//   /deep/ .el-table__body-wrapper {
//     background: #ffffff;
//   }
//   ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
//     background-color: #fff !important;
//   }
  /deep/ .el-pagination {
    display: flex;
    justify-content: flex-end;
  }
  /deep/ .el-pagination button:disabled {
    background: rgba(255, 255, 255, 0);
  }
  /deep/ .el-pager li.active {
    color: #333;
    background-color: #fff;
    cursor: default;
  }
  /deep/ .el-pager li {
    background: none;
    color: #333;
  }
  /deep/ .el-icon {
    color: #333;
  }
  /deep/ .el-pagination .btn-next {
    background: none;
  }
  /deep/ .el-pagination__total {
    color: #333;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 12px;
  }
  /deep/ .el-pagination__jump {
    color: #333;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 15px;
  }
  /deep/ .el-input__inner {
    color: #333;
  }
}
</style>
